import {Authenticator} from "@aws-amplify/ui-react";
import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";

const AddInstitution = () => {

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        sessionStorage.clear();
        navigate(path); // Navigiere zur angegebenen Route
        window.location.reload(); // Lade die Seite neu, nachdem die Navigation erfolgt ist
    };

    const [formData, setFormData] = useState({
        name: '',
        type: 'precisis',
        address: '',
        contact: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        // console.log(e.target.value);
    };

    // Initialisiert den State mit dem Anfangswert des Dropdowns
    const [selectedValue, setSelectedValue] = useState('');

    // Handler, der aufgerufen wird, wenn ein neuer Wert ausgewählt wird
    const handleChange2 = (event) => {
        setSelectedValue(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Hier können Sie den Code hinzufügen, um das Formular zu verarbeiten
        // console.log(formData.name);

        const raw = "{\r\n    \"institution\": {\r\n        \"name\": \"" + formData.name + "\",\r\n        \"type\": \"" + selectedValue + "\",\r\n        \"address\": \"" + formData.address + "\",\r\n        \"contact\": \"" + formData.contact + "\"\r\n    }\r\n}";
        let response = "";
        try {
            response = await fetch("https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/dev/addupdateinstitution", {
                method: 'POST',
                body: raw,
                headers: {
                    "Content-Type": "application/csv",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
        } catch (error) {
            console.error('Error uploading file:', error);
        }

        let jsonData = await response.json();
        if (jsonData.errorMessage) {
            window.alert("Error while saving institution");
            // window.alert("Error: "+jsonData.stackTrace[0]);
            // console.log(jsonData);
        } else {
            if (jsonData["statusCode"] === 200) {
                // console.log(jsonData);
                window.alert(jsonData["body"]);
                handleNavigation('/');
            }
            if (jsonData["statusCode"] === 999) {
                // console.log(jsonData);
                window.alert(jsonData["body"]);
            }
        }
    };

    return(
        <Authenticator>
            {({signOut }) => (
                <div>
                    <div className="signoutbtn">
                        <button onClick={signOut}>Sign Out</button>
                    </div>
                    <div className={"page_title"}>
                        <h1>ADD INSTITUTION</h1>
                    </div>
                    <main className={"main_process_page"}>
                        <div className="horizontal-line w-[100%] mt-4 mb-4"></div>
                        <div className={""}>
                            <h1 className={"font-bold text-[#A5B9C3]"}>Input Institution</h1>
                            <div className="horizontal-line w-[100%] mt-4"></div>
                            <form onSubmit={handleSubmit}>
                                <div className={"text-[#A5B9C3]"}>
                                    <label htmlFor="name" className="text-[#A5B9C3] block mb-2 text-sm font-medium dark:text-white">Name</label>
                                    <input value={formData.name} onChange={handleChange} type={"text"} name="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Precisis"></input>
                                    <label htmlFor="countries" className="text-[#A5B9C3] block mb-2 text-sm font-medium dark:text-white">Select type</label>
                                    <select value={selectedValue} onChange={handleChange2} name="type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option value="">Select Type</option>
                                        <option value="Precisis">Precisis</option>
                                        <option value="Medical Entity">Medical Entity</option>
                                        <option value="Clinical Entity">Clinical Study</option>
                                    </select>
                                    <label htmlFor="address" className="text-[#A5B9C3] block mb-2 text-sm font-medium dark:text-white">Address</label>
                                    <input value={formData.address} onChange={handleChange} type={"text"} name="address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="4471 Walnut Street"></input>
                                    <label htmlFor="contact" className="text-[#A5B9C3] block mb-2 text-sm font-medium dark:text-white">Contact</label>
                                    <input value={formData.contact} onChange={handleChange} type={"text"} name="contact" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Prof. Hermann Littel Jr."></input>

                                </div>
                                <button type="submit" className={"mt-4 rounded-button"}>Save</button>
                            </form>
                        </div>
                        <div className="horizontal-line w-[100%] mt-4"></div>
                    </main>

                </div>
            )}
        </Authenticator>
    );
};

export default AddInstitution;