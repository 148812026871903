import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import './App.css';
import { BrowserRouter as Router, Routes, Route, NavLink} from 'react-router-dom';
import ReactLogo from './logo.svg';
import Upload from './pages/upload';
import Process_page from './pages/process_page';
import AddInstitution from './pages/addInstitution';
import Dashboard from './pages/dashboard';
import React from "react";
import {Authenticator} from "@aws-amplify/ui-react";

Amplify.configure(awsconfig);

function App() {
  return (
    <div className="App">
        <div className="content">
            <Router>

                    <nav className="navbar">
                        <div className="logo">
                            <img src={ReactLogo} alt="Logo" className="logo"/>
                        </div>
                            <div className="menu">
                                {/*<NavLink className='btn btn-sm text-white hover:text-black' exact activeClass="active" to="/">Home</NavLink>*/}
                                <NavLink className='btn btn-sm text-white hover:text-black' activeClass="active" to="/upload">Upload</NavLink>
                                {/*<NavLink className='btn btn-sm text-white hover:text-black' activeClass="active" to="/process_page">Process_page</NavLink>*/}
                                <NavLink className='btn btn-sm text-white hover:text-black' activeClass="active" to="/addInstitution">Add institute</NavLink>
                                <NavLink className='btn btn-sm text-white hover:text-black' activeClass="active" to="/dashboard">Dashboard</NavLink>
                            </div>
                    </nav>

                <Routes>
                    {/*<Route path='/' element={<Home />}></Route>*/}
                    <Route path='/' element={<Upload />}></Route>
                    <Route path='/upload' element={<Upload />}></Route>
                    <Route path='/process_page' element={<Process_page />}></Route>
                    <Route path='/dashboard' element={<Dashboard />}></Route>
                    <Route path='/addInstitution' element={<AddInstitution />}></Route>
                </Routes>
            </Router>
        </div>
    </div>

  );
}

export default App;
